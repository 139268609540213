import './App.css'
import publicIp from 'public-ip'
import { useEffect, useState } from 'react'
import axios from 'axios'
import './../node_modules/bootstrap/dist/css/bootstrap.min.css'

let ipaddress, ipdatastore
function App() {
  const [ipdata, setIpData] = useState({})
  const [error, setError] = useState(false)
  const [input, setInput] = useState('')
  useEffect(() => {
    const getIpAddress = async () => {
      ipaddress = await publicIp.v4()
      ipdatastore = await axios.get(`https://ipapi.co/${ipaddress}/json/`)
      setIpData(ipdatastore.data)
    }
    getIpAddress()
  }, [])

  const findIpAddress = async (ipaddressinput) => {
    let findbyip = await axios.get(`https://ipapi.co/${ipaddressinput}/json/`)
    if (findbyip.data.error === true) {
      return setError(true)
    }
    setIpData(findbyip.data)
  }

  return (
    <div className='App' style={{ fontFamily: 'sans-serif' }}>
      <br />
      <h1 style={{ textAlign: 'center' }}>IP Finder</h1>
      <br />
      <div className='form-group'>
        <input
          style={{ width: '200px' }}
          onKeyUp={(e) => setInput(e.target.value)}
          onKeyPress={(i) =>
            i.target.key === 'Enter' ? findIpAddress(input) : null
          }
        />
        <small id='helpId' className='form-text text-muted'>
          Enter IP Address ({ipdata.ip})
        </small>
        <br />
        <input
          type='button'
          value='IP LOOK UP'
          style={{
            backgroundColor: 'orange',
            border: 'none',
            color: 'white',
            borderRadius: '5px',
            outline: 'none',
          }}
          onClick={(e) => findIpAddress(input)}
        />
      </div>
      {error ? (
        <h1 style={{ textAlign: 'center' }}>Please Enter a valid Address</h1>
      ) : (
        <div className='tableData'>
          <table className='table table-bordered'>
            <tr>
              <td width='200px' style={{ textAlign: 'start' }}>
                IP
              </td>
              <td width='400px'>{ipdata.ip}</td>
            </tr>
            <tr>
              <td width='200px' style={{ textAlign: 'start' }}>
                IP Version
              </td>
              <td width='400px'>{ipdata.version}</td>
            </tr>
            <tr>
              <td width='200px' style={{ textAlign: 'start' }}>
                City
              </td>
              <td width='400px'>
                {ipdata.city} / {ipdata.region_code}
              </td>
            </tr>
            <tr>
              <td width='200px' style={{ textAlign: 'start' }}>
                Country
              </td>
              <td width='400px'>
                {ipdata.country_name} / {ipdata.country_code} /{' '}
                {ipdata.continent_code}
              </td>
            </tr>
            <tr>
              <td width='200px' style={{ textAlign: 'start' }}>
                Country Captial
              </td>
              <td width='400px'>{ipdata.country_capital}</td>
            </tr>
            <tr>
              <td width='200px' style={{ textAlign: 'start' }}>
                Zip
              </td>
              <td width='400px'>{ipdata.postal}</td>
            </tr>
            <tr>
              <td width='200px' style={{ textAlign: 'start' }}>
                Latitude / Longitude
              </td>
              <td width='400px'>
                {ipdata.latitude} / {ipdata.longitude}
              </td>
            </tr>
            <tr>
              <td width='200px' style={{ textAlign: 'start' }}>
                TimeZone
              </td>
              <td width='400px'>
                {ipdata.timezone} ({ipdata.utc_offset})
              </td>
            </tr>
            <tr>
              <td width='200px' style={{ textAlign: 'start' }}>
                Calling Code / Currency
              </td>
              <td width='400px'>
                {ipdata.country_calling_code} / {ipdata.currency}
              </td>
            </tr>
          </table>
        </div>
      )}
    </div>
  )
}

export default App
